<template>
  <div>
    <tab-bar>
      <tab-bar-item path="/Home" activeColor="#3c82ff ">
        <img slot ="item-icon" src="~assets/images/home.png" alt="">
        <img slot ="item-icon-active" src="~assets/images/homes.png" alt="">
        <div slot="item-text">首页</div>
      </tab-bar-item>
      <tab-bar-item path="/Myorder" activeColor="#3c82ff ">
        <img slot ="item-icon" src="~assets/images/dingdan.svg" alt="">
        <img slot ="item-icon-active" src="~assets/images/dingdans.svg" alt="">
        <div slot="item-text">订单</div>
      </tab-bar-item>
      <tab-bar-item path="/Profileuser" activeColor="#3c82ff ">
        <img slot ="item-icon" src="~assets/images/user.png" alt="">
        <img slot ="item-icon-active" src="~assets/images/users.png" alt="">
        <div slot="item-text">我的</div>
      </tab-bar-item>
    </tab-bar>
  </div>
</template>

<script>
import TabBar from '@/components/pluginunit/tabbar/Tabbar'
import TabBarItem from '@/components/pluginunit/tabbar/Tabbaritem'
export default {
  name: 'MainTabBar',
  components: {
    TabBar,
    TabBarItem
  },
  data () {
    return {
    }
  }
}

</script>
<style lang="scss" scoped>
</style>

<template>
  <div class="warp">
    <!-- 轮播-start -->
    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in swipeList" :key="index"><img :src="item.imgUrl" alt=""></van-swipe-item>
      </van-swipe>
    </div>
    <!-- 轮播-end -->
    <div class="main">
      <!-- 垃圾种类 -->
      <div class="main-bt">
        <img src="@/assets/images/ljzl.png" alt="">
        <span>垃圾种类</span>
      </div>
      <div class="itemList">
        <div class="content" @click="jumpClick(item)" v-for="(item, index) in itemList" :key="index">
          <img :src="item.imageUrl" alt="">
          <p>{{item.name}}</p>
        </div>
      </div>
      <!-- 垃圾种类 end -->
      <!-- 政策 -->
      <div class="main-bt1">
        <img src="@/assets/images/zc.png" alt="">
        <span>政策</span>
      </div>
      <div class="main-dh">
        <div class="main-hd">
          <div class="main-nr" v-for="(item, index) in itemListxw" :key="index" @click="zcClick(item)">
            <img :src="findQuery(item)">
            <div class="right">
              <p>{{item.noticeTitle}}</p>
              <span>{{item.remark}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 政策 end -->
    </div>
    <main-tab-bar></main-tab-bar>
  </div>
</template>

<script>
import MainTabBar from '@/components/assembly/Maintabbar.vue'
import { login, categoryId, getInfo, noticelist, dictType } from '@/api/notice.js'
import { Toast } from 'vant'
export default {
  name: 'Home',
  components: {
    MainTabBar,
  },
  data() {
    return {
      swipeList: [
        {
          imgUrl: require('@/assets/images/lunbo1.png')
        },
        {
          imgUrl: require('@/assets/images/lunbo2.jpg')
        },
        {
          imgUrl: require('@/assets/images/lunbo3.jpg')
        }
      ],
      imgUrl: undefined,
      itemList: [],
      itemListxw: [],
      query: {
        pageNum: 1,
        pageSize: 3,
      }
    }
  },
  beforeCreate() {
    const openid = process.env.VUE_APP_DEFAULT_USER_OPENID
    if (openid) {
      login({ openid: openid}) // 用户登入
    }
    getInfo().then(res => {
      if (res.data.code === 200) {
        const hsCompanyUser = res.data.data.hsCompanyUser
        if (!hsCompanyUser) {
          this.$router.push({path: 'Errorpage', query: {msg: '对不起，您还未绑定产废企业，请现在注册产废企业或扫码企业码'}})
        } else {
          let msg = ''
          if (hsCompanyUser.isPass === 2) {
            msg = '审核未通过...'
          } else if (hsCompanyUser.isPass === 0) {
            msg = '正在审核中...'
          } else if (hsCompanyUser.isPass === 1) {
            return this.$router.push({path: 'Home'})
          }
          this.$router.push({path: 'Errorpage', query: {msg: msg}})
        }
      }
    })
  },
  created() {
    this.categoryId()
    this.noticelist()
    this.dictType()
  },
  methods: {
    dictType() { // 字典
      dictType('sys_notice_type').then(res => { // 单位类型
        if (res.data.code === 200) {
          this.imgUrl = res.data.data
        }
      })
    },
    findQuery(item) { // 翻译字典
      return this.imgUrl.filter(items => item.noticeType == items.dictValue)[0].remark
    },
    categoryId() { // 获取所有分类
      const id = 0
      categoryId(id).then(res => {
        this.itemList = res.data.data.children
      })
    },
    noticelist() { // 政策
      noticelist(this.query).then(res => {
        if (res.data.code === 200) {
          this.itemListxw = res.data.rows
        }
      })
    },
    jumpClick(item) {
      this.$router.push({path: 'Category', query: {id: item.id}})
    },
    zcClick(item) { // 政策跳转
      this.$router.push({path: 'Policydetails', query: {id: item.noticeId}})
    }
  }
}
</script>
<style lang="scss" scoped>
.warp {
  .banner {
    width: 100%;
    height: 175px;
    overflow: hidden;
    .van-swipe-item {
      img {
        width: 100%;
        height: 175px;
      }
    }
  }
  .main {
    background-color: #fff;
    padding: 20px 15px 10px;
    box-sizing: border-box;
    .main-bt, .main-bt1 {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 30px;
        margin-right: 10px;
      }
      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
    .main-bt1 {
      margin-bottom: 25px;
    }
    .itemList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0 20px;
      .content {
        text-align: center;
        img {
          width: 30px;
          margin-bottom: 7px;
        }
        p {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
    .main-dh {
      min-height: 220px;
      max-height: 220px;
      background-color: #fff;
      overflow: hidden;
      .main-hd {
        // animation: myfirst 5s linear 0s infinite;
        .main-nr {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          img {
            width: 80px;
            margin-right: 15px;
          }
          .right {
            p, span {
              letter-spacing: 0;
              overflow: hidden;
              display: -webkit-box;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
            }
            p {
              color: #666666;
              font-weight: 600;
              font-size: 14px;
              margin-bottom: 5px;
              -webkit-line-clamp: 1;  /*要显示的行数*/
            }
            span {
              -webkit-line-clamp: 2;  /*要显示的行数*/
              font-size:12px;
              color: #999999;
            }
          }
        }
      }
    }
  }
}
@keyframes myfirst
{
  from{
    margin-top: 0px;
  }
  to{
    margin-top: -225px;
  }
}
</style>